.App{
    text-align: center;
    width:100%;
    height:100%;
    
  
   
}
h1{
  text-align: center;
  color:#fff;
  font-weight: normal;
  margin-bottom: 20px;
}
input{
  width: 100%;
  background: none;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px 20px;
  color: white;
  box-sizing: border-box;
  margin-bottom: 20px;

}
textarea{
  width: 100%;
  background: none;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px 20px;
  color: white;
  box-sizing: border-box;
  margin-bottom: 20px;
  height:200px;
} 
.container{
  display:flex;
  justify-content: space-between;
  
}

.flex-container{
 
    
    background-color: red;
    
     
   
    
   
    
   }
   .btn{
    border: 2px solid #FF0000;
    color:red;
    border-radius: 30px;
    padding-right:15px;
    padding-left:15px;
    text-decoration: none;
    
  }
   .description{

    width:450px;
   
    font-weight:500;
    font-size: 20px;
    text-align: left;
    
  
    
    
  }
   .propos{
  

    padding-right: 40px;
    color: red;
    text-decoration: underline;
    
    
    
    
    }
    .btn-1{
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      
      
      margin-top: 120px;
      width:170px;
      
      background-color: white;
      text-align: center;
     height: 60px;
      border-radius: 30px;
      padding-right: 30px;
      padding-left: 30px;
     
     
      
    }
    .text-4{
      text-decoration: none;
      padding-left:4px;
      color: red;
    }
    .text-3{
      padding-bottom:2px;
      padding-left:20px;
    }
    
    .contact{
      display: flex;
      
      justify-content: center;
      align-items: center;
      
     
      
      background-color: red;
      height: 250px;
      color: white;
      
    }
   .image2{
    padding-top:30px;
    padding-left:30px;
    
    
    }
    .eda{
      padding-right: 550px;
      padding-top:50px;
     
      
    }
   .image{
  
    float:left;
    padding-top:17px;
    padding-left:300px;
  }
  .texts{
    float:left;
    padding-top:48px;
    padding-left:80px;
    color:white;
    font-family: Calibri;
    
  
  }
  .menu{
    width:500px;
    display:flex;
    justify-content: space-between;
    padding-top:78px;
    padding-left:300px;
    padding-right:70px;
  }
  
  .link{
    text-decoration: none;
    color:white;
  }
  .pgm{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
 
  
    
  }
  .pgm-4{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap:10px;
  list-style: none;
  border: 1px solid red;
  border-width: 5px;
  border-radius: 10px;
  width: 700px;
  padding-bottom: 20px;
  }
   .pgm-3{
  border: 1px solid black;
  display: flex;  
  width:650px;
 justify-content: center;
  height: 100%;
  color: black;
  }
 .drt{
  background-color: red;
  border: none;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  height: 60px;
  
 }
 .pgm-5:last-child{
  color:white;
 }
.pgm-5:last-child:hover{
  color: white;
  opacity:0.7;
}

.dropdown{
  position: relative;
  display: inline-block;
}

.pgm-5{
  text-align: center;
  border: none;
  font-size: 35px; 
  cursor: pointer;  
}
.pgm-2{
font-size: 20px;
}
.dropdown-content{
 display: none;
  position: absolute;
  background-color: #f1f1f1;
  text-align: left;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  font-weight: 600;
  display: block;
}
.dropdown-content a:hover {background-color: #ddd;}
.link-2{
  color:white;
}
.link-2:hover .dropdown-content{
  display: block;
}
.curva{
  color: black;
  text-align: center;
}
@media (min-width:2240px){
  .pgm{
    width: 130rem;
    display: flex;
    justify-content: center;
  }
}
@media (min-width:1600px) and (max-width:1900px){

}

@media (min-width:1440px) and (max-width:1599.9px){
  .pgm{
    display: flex;
    justify-content: center;
   width: 100rem;
  }
}
@media (min-width:1024.1px) and (max-width:1366px){
  .pgm{
    width: 85rem;
  }
}

@media (min-width:768px) and (max-width: 1024px){
  .pgm-4 form h2{
    font-size: 5rem;
  }
  .pgm-4{
    width: 82rem;
    text-align: center;
    border:2rem solid red;
  }
  .pgm-2{
    width: 80rem;
    text-align: center;
    font-size: 3rem;
  }
  .pgm-3{
    width: 80rem;
    font-size: 3rem;
  }
  .pgm-4 form li{
    margin-bottom: 5rem;
  }
  .pgm{
   margin-left: 30rem;
  }
  .drt{
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .drt input{
    font-size: 4.5rem;
  }
}
@media (min-width:600.03px) and (max-width: 768px){
  .pgm-4 form h2{
    font-size: 5rem;
  }
  .pgm-4{
    width: 90rem;
    text-align: center;
  }
  .pgm-2{
    width: 80rem;
    text-align: center;
    font-size: 3rem;
  }
  .pgm-3{
    width: 80rem;
    font-size: 3rem;
  }
  .pgm-4 form li{
    margin-bottom: 5rem;
  }
  .drt{
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .drt input{
    font-size: 4.5rem;
  }
}
@media(max-width:600px){
  .App{
    max-width:100rem;
    height: 100%;
    overflow-x: hidden !important;
  }
  .containera{
    max-width: 100rem;  
    padding: 0;
    margin: 0;
    height: 25rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: red;

   }
   .imagea img{
    height:100%;
    width: 15rem;
    margin-left: 15rem;
   }
   
   .texts h1{
    color:white;
    font-family: Calibri;
    width: 100%;
    height: 100%;
    font-size: 3em;
  }
  .texts{
    width: fit-content;
    visibility: hidden;
  }
 
  
  .allah a{
  font-size: 2.2rem;
  z-index:10000;
}

.dropdown-content{
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  height: 50rem;
  gap:11rem;
  z-index: 1;
  padding-right: 20rem;
}
  .curva{
    color: black;
    text-align: center;
    padding-right:10rem; 
  }
  .dropdown-content a{
    width: 16rem;
    
  }
  .dropdown-content-1 a{
    font-size: 2.2rem;
    
  }
  .dropdown-content-1{
    gap:9rem;
    
  }
  .pgm{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
  .pgm-4{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    list-style: none;
    height: 165rem;
    border:2rem solid red;
    width: 80rem;
    padding-bottom: 5rem;
  }
  .pgm-4 form h2{
    font-size: 3.5rem;
    text-align: center;
  }
  .pgm-4 form li{
    margin-bottom: 5rem;
  }
  .pgm-2{
    font-size: 4rem;
    width: 80rem;
    text-align: center;
  }
  .pgm-3{
    font-size: 4rem;
    width: 75rem;
  }
 .drt{
  height: 7rem;
  width: 75rem;
 }
 .pgm-5{
  font-size: 5rem;
 }
}
@media(max-width:380px){
  .pgm-4{
    width: 80rem;
    margin-right: 10rem;
    padding-bottom: 5rem;
  }
 .pgm-4 form li{
width: 100%;
 }
}
 

.App {

    text-align: center;
    width:1920px;
    height:1080px;
   
    
    
  }
  .maintenance{
    color:black;
    font-weight: 900;
    width:350px;
    text-align: left;
  }
  .content-3{
    width:350px;
    text-align: left;
    font-weight: 600;
   
  }
  .content{
    display: flex;
    justify-content: center;
    gap:70px;
    padding-top:80px;
  }
  .devis{
    display: flex;
    background-image: linear-gradient(rgba(0,0,0,0.527),rgba(0,0,0,0.5)),url("./images/article_acheter_devis.jpg" );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color:white;
  height:300px;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  }
  .p{
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-size: 15px;
  }
  .btn-2{
    background-color: red;
    width:200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    margin-left: 500px;
    border-radius: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    gap:3px;
    cursor:pointer;
    

  }
  .btn-2:hover a{
color:black;
  }
  .btn-2:hover{
    background-color: white;
    
  }
  @media (min-width:2240px){
    .devis{
      width: 145rem;
      display: flex;
      justify-content: center;
    }
    .devis h2{
      margin: 14rem;
    }
    
  }
  @media (min-width:1600px) and (max-width:1900px){
    .devis h2{
      margin-right: 23rem;
    }
  }
  
  @media (min-width:1440px) and (max-width:1599.9px){
    .content{
      margin-right: 30rem;
    }
    .btn-2{
      margin-left: 15rem;
    }
    .devis{
      margin-right: 30rem;
    }
  }
  @media (min-width:1024.1px) and (max-width:1366px){
    .content{
      width: 85rem;
    }
    .btn-2{
      margin-left:11rem;
    }
  .devis{
    width: 85rem;
  }
  }
  @media (min-width:768.1px) and (max-width: 1024px){
    .maintenance{
      font-size:4rem;
      width: 80rem;
    }
    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .content-3{
      font-size: 3rem;
      width: 80rem;
    }
    .content img{
      width: 80rem;
    }
    .devis{
      min-width: 150rem;
    }
    .btn-2{
      display: flex;
      align-self: start;
      margin-left: 20rem;
      width: 40rem;
      gap:1rem;
      height: 5rem;
      margin-top: 1rem;
    }
    .p{
      font-size: 3.5rem;
    }
    .btn-2 img{
      height: 4rem;
    }
    .devis{
      font-size: 4rem;
      width: 125rem;
    }
    .contact img{
      height: 5rem;
    }
   .contact{
    width: 125rem;
   }
   .footer-2{
    width: 125rem;
   }
  }
  @media (min-width:600.1px) and (max-width: 768px){
    .maintenance{
      font-size:4rem;
      width: 80rem;
    }
    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .content-3{
      font-size: 3rem;
      width: 80rem;
    }
    .content img{
      width: 80rem;
    }
    .btn-2{
      display: flex;
      align-self: start;
      margin-left: 20rem;
      width: 40rem;
      gap:1rem;
      height: 5rem;
      margin-top: 1rem;
    }
    .p{
      font-size: 3.5rem;
    }
    .btn-2 img{
      height: 4rem;
    }
    .devis{
      font-size: 4rem;
      width: 125rem;
    }
    .contact img{
      height: 5rem;
    }
   .contact{
    width: 125rem;
   }
   .footer-2{
    width: 125rem;
   }
  }
  @media (min-width:375px) and (max-width:600px){
    .content{
      width: 100rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
.maintenance{
  font-size: 4rem;
  width: 80rem;
}
.content-3{
  font-size: 3rem;
  width: 80rem;
}
.content img{
  width: 80rem;
}
.btn-2{
  width: 45rem;
  height: 10rem;
  display: flex;
  align-self:start;
  margin-left: 10rem;
  margin-top: 1rem;
  gap:2rem;
}
.btn-2 img{
  height: 4.3rem;
}
.p{
  font-size: 4rem;
}
.devis{
  font-size: 3rem;
}
.contact{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: red;
  color: white;
  width: 100rem;
}
.contact img{
  height: 6rem;
}
  }
  @media (max-width:380px){
    .content img{
      width: 75rem;
      padding-right: 5rem;
    }
  }
 
  


  